import React, { Component} from 'react'
import {navigate, graphql, Link} from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import BlogCarousel from '../components/Carousel/blogCarousel'
import {
	Breadcrumb,
	Container,
	Grid,
	Icon,
	Loader,
	Image, //commented it out since banner isn't used for now because  it was confused as a link
	Pagination,
} from 'semantic-ui-react'
import ApiConfig from '../../config/api-config'

import ProductList from '../components/ProductList'
import SubCategoryList from '../components/SubCategoryList'
import * as styles from './styles/template.module.css'
import Carousel from '../components/Carousel'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../globalVar'

const Negosyo = require('../../lib/negosyo')


export default class Category extends Component {
	state = {
		rating : undefined,
		supplier: undefined,
		products: undefined,
		masterProducts: undefined,
		shown:undefined,
		mobile:undefined,
		limit:20,
		currentPage:1,
		paginationRangeMaster:4,
		paginationRange:4,
		timeOut1:undefined, //for resize
		timeOut2:undefined,
		loadingProducts:true,
		blog:[],
		newitem:[],
		bestseller:[],
	};


	componentDidMount() {
		this.fetchData();
		this.handleParameters();
		this.handler();
		window.addEventListener('resize', this.resizeTimerFunc);
	}

	resizeTimerFunc = () => {
		clearTimeout(this.state.timeOut1);
		const temp = setTimeout(this.handler, resizeTimer);
		this.setState({
			timeOut1:temp
		})
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOut1);
		clearTimeout(this.state.timeOut2);
		window.removeEventListener('resize', this.resizeTimerFunc);
	}

	handleParameters = () => {
		const {location} = this.props
		if(location.search) {
			const parameters = location.search.split("&")
			/*important checks if current result is already matched,
			 *without this if statement, there'll be an infinite loop
			 */
			for( let i =0; i < parameters.length; i++) {
				if(parameters[i].split("=")[0] === "page" || parameters[i].split("=")[0] === "?page") {
					this.setState({ currentPage:parameters[i].split("=")[1] })
				}else {
					//unplanned parameters
				}
			}

		}
	}


	handler = () => {
		//computer
		if (window.innerWidth > tabletMaxWidth ) {
			this.setState({
				//shown: related.length > 0 ? 4:5,
				shown: 5,
				mobile:false,
				paginationRange:4
			})
		} //tablet
		else if (window.innerWidth > mobileMaxWidth ) {

			this.setState({
				shown : 5,
				mobile:true,
				paginationRange:2
			})
		} else {  //mobile
			this.setState({
				shown : 2,
				mobile:true,
				paginationRange:1
			})
		}
	}


	fetchData = () => {

		Negosyo.getProductSearch(this.props.pageContext.url) //reused url since its already being passed as Context
		.then((response) => {
			if(Array.isArray(response) ) {
				this.setState({
					masterProducts:response,
					products:response,
					loadingProducts:false
				})
			}else {
				//handle product search api error
				this.setState({
					masterProducts:[],
					products:[],
					loadingProducts:false
				})
			}

		}).catch((err)=>{
			//console.log(err)
			this.setState({loadingProducts:false})
		})

		this.fetchBlogData()

		// Added 2021-03-29, included slash
		Negosyo.getProductSearch("new/top/"+this.props.pageContext.url)
		.then((response)=> {
			this.setState({newitem:response})
		}).catch((err)=>{
			//this.setState({loadingNew:false, errorNew:true})
		})

		Negosyo.getProductSearch("bestseller/top/"+this.props.pageContext.url)
		.then((response)=> {
			this.setState({bestseller:response})
		}).catch((err)=>{
			//this.setState({loadingNew:false, errorNew:true})
		})

	}

	fetchBlogData = (retryTimer=2000)=>{
		const {
			url
		} = this.props.pageContext
		fetch(`${ApiConfig.protocol}://${ApiConfig.host}/graphql`, {
			method: 'POST',
			headers: {

			},
			body: JSON.stringify({
				query: `query {
					viewCategory(url:"${url}") {
						recipe {
							name
							squareImageHref
							summary
							url
						}
					}
				}`,
			}),
		})
		.then(response => {
			return response.json()
		})
		.then(responseAsJson => {
			this.setState({
				blog:responseAsJson.data.viewCategory.recipe,
			})
		})
		.catch( (error) => {
			const timeOut = setTimeout(()=>{
				this.fetchBlogData(retryTimer+1000)
			}, Math.min(5000, (retryTimer+1000) ))
			this.setState({
				timeOut2:timeOut
			})
		})
	}

	render() {
		const location = this.props.location
		const {
			products,
			shown,
			mobile,
			currentPage,
			limit,
			paginationRange,
			loadingProducts,
			newitem,
			bestseller,
			blog
		} =this.state


		let slicedProducts
		let length = 0
		let totalPages;
		if(products) {
			slicedProducts = products
			length = slicedProducts.length
			totalPages=Math.ceil(length/limit)
			slicedProducts = slicedProducts.slice( (currentPage-1)*limit, limit*currentPage)
		}
		const data = this.props.data.negosyoNow.viewCategory
		const {
			related,
			name,
			//mainImageHref, commented it out since banner isn't used for now because  it was confused as a link
			breadcrumb,
			squareImageHref,
			url,
			summary
		} = data

		return (
			<Layout location={location} backButton={true}>
				<SEO title={name}
					canonicalpath={"/"+url}
					description={summary}
					image={squareImageHref} imageWidth={500} imageHeight={400}/>

				<Container className={styles.container}>
					{breadcrumb.length >0 && (
						<React.Fragment>
							<Breadcrumb className={styles.desktopOnly}>
								<Breadcrumb.Section as={Link} to="/" content="Home"/>
								{breadcrumb.map((breadcrumbs, index)=>{
									let props = {}
									if(index+1!==breadcrumb.length) {
										props={
											as:Link,
											to:`/${breadcrumbs.categoryUrl === "negosyo-packages"? "business": breadcrumbs.categoryUrl}/`
										}
									}
									return (
										<React.Fragment key={breadcrumbs.categoryName}>
											<Breadcrumb.Divider/>
											<Breadcrumb.Section
												{...props}
											>
												{breadcrumbs.categoryName}
											</Breadcrumb.Section>
										</React.Fragment>
									)
								})}
							</Breadcrumb>
							<Breadcrumb className={styles.mobileOnly}>
								{breadcrumb.length > 1 ? (
									<Breadcrumb.Section as={Link} to={breadcrumb[breadcrumb.length-2].categoryUrl==="negosyo-packages" ? "/business/": `/${breadcrumb[breadcrumb.length-2].categoryUrl}/`}>
										<Icon name="arrow left"/>
										{breadcrumb[breadcrumb.length-2].categoryName}
									</Breadcrumb.Section>
								):(
									<Breadcrumb.Section as={Link} to="/">
										<Icon name="arrow left"/>
										Home
									</Breadcrumb.Section>
								)}

							</Breadcrumb>
						</React.Fragment>
					)}
					{ url==="promo" && (
						<Image
							src={"https://cdn.ngnw.ph/images/asset/nn_limited_offers.jpg"}
							className={styles.banner}
						/>
					)}
					<Grid className={styles.container}>
						{ related.length > 0 &&(
							<Grid.Column width={16} className={styles.gridMod}>
								<Carousel
									items={related}
									shown={5}
									initialShown={5}
									tabletShown={5}
									mobileShown={3}
									categoryClassName={styles.carouselHeader}
									header={"Related Categories"}
									type={"category"}
									showHeader={false}
								/>
							</Grid.Column>
						)}
						{ blog.length > 0 &&(
							<Grid.Column width={16} className={styles.gridMod}>
								<div className={`${styles.header} ${styles.centerText} ${styles.headerMarginMod}`}>{`Top recipes and guides under ${name}`} </div>
								<BlogCarousel
									items={blog}
								/>
							</Grid.Column>
						)}
						{ (mobile === false && related.length > 0 && false) && (
							<React.Fragment >
								<Grid.Column
									width={4}
								>
									<SubCategoryList
										relatedcategorylist={related}
										key={"relatedCategory list"}
									/>
								</Grid.Column>
							</React.Fragment>
						)}
						{ (bestseller.length > 0 && url !== "promo") && (
							<Grid.Column width={16} className={styles.gridMod}>
								<div className={`${styles.header} ${styles.centerText} ${styles.headerMarginMod}`}>{`Popular items under ${name}`} </div>
								<Carousel
									items={bestseller}
									shown={6}
									initialShown={6}
									tabletShown={6}
									mobileShown={3}
									header={"Popular"}
									type={"product"}
									showHeader={false}
									listName={`Category-${name}-Popular`}
								/>
							</Grid.Column>
						)}
						{ (newitem.length > 0 && url !== "promo") && (
							<Grid.Column width={16} className={styles.gridMod}>
								<div className={`${styles.header} ${styles.centerText} ${styles.headerMarginMod}`}>{`New items under ${name}`} </div>
								<Carousel
									items={newitem}
									shown={6}
									initialShown={6}
									tabletShown={6}
									mobileShown={3}
									header={"New Items"}
									type={"product"}
									showHeader={false}
									listName={`Category-${name}-New`}
								/>
							</Grid.Column>
						)}
						<Grid.Column
							width={ (mobile || mobile===undefined || true) ? 16 : 12}
						>
							<span id="Products" className={styles.anchor}/>
							<div className={`${styles.header} ${styles.centerText} ${styles.headerMarginMod}`}><h1>{length ? url !== "promo" ? `${length} products under ${name}`: "Check out these ongoing promotions": url !== "promo"?"":"No promotions active at the moment"}</h1></div>
							{ !loadingProducts && slicedProducts ? (
								<React.Fragment>
									{slicedProducts.length > 0 && (
										<ProductList
											products={slicedProducts}
											shown={shown}
											grid={(mobile || related.length === 0 || true) ? 16 : 12}
											cameFrom={`category-${name}`}
											page={currentPage}
											offset={20}
											listName={`Category-${name}`}
										/>
									)}
									{ totalPages > 1 && (
										<React.Fragment>
											<br/>
											<Pagination
												activePage={currentPage}
												onPageChange={(e, {activePage})=>{
													this.setState({
														currentPage:activePage
													})
													navigate(`${location.pathname}?page=${activePage}#Products`)
												}}
												ellipsisItem={null}
												boundaryRange={null}
												siblingRange={paginationRange}
												totalPages={totalPages}
												pageItem={{
													className:styles.paginationItem
												}}
												firstItem={{
													className:styles.paginationItem,
													content:"<<"
												}}
												lastItem={{
													className:styles.paginationItem,
													content:">>"
												}}
												nextItem={{
													className:styles.paginationItem,
													content:">"
												}}
												prevItem={{
													className:styles.paginationItem,
													content:"<"
												}}
											/>
										</React.Fragment>
									)}

								</React.Fragment>
							) : (
								<Loader active inline="centered" key={"loader"}/>
							)}

						</Grid.Column>
					</Grid>
				</Container>

			</Layout>
		)
	}
}
export const data = graphql`
	query CategoryQuery($url:String!) {
		negosyoNow {
			viewCategory( url: $url ) {
				related {
						name
						url
						squareImageHref
				}
				name
				summary
				url
				mainImageHref
				squareImageHref
				breadcrumb {
					categoryName
					categoryUrl
				}

			}
		}
	}
`
/*
{ mainImageHref &&
						<Image
							src={mainImageHref}
							className={`${styles.banner} ${styles.container}`}
						/>
					}
*/


/* for controlling limit, took it out for now on 5/6/2020
						<span># of products shown</span>
						<Dropdown
							value={limit}
							options={options}
							placeholder="# of products shown"
							selection
							onChange={(e, {value})=>{
								this.setState({limit:value});
							}}
							className={styles.limitDropdown}
						/>
*/

/* <Price
                handleSubmit={handleSubmit}
              /> */

