import React, { Component } from 'react'
import {Link} from 'gatsby'
import * as styles from './styles/subCategoryList.module.css'


class SubCategoryList extends Component {



	render()
	{
		const {relatedcategorylist} = this.props
		const filteredCategoryList = relatedcategorylist.filter((category)=>category.url)
		return(
			<>
				<div className={styles.header}>
					Related Categories
				</div>
				{filteredCategoryList.map( (relatedCategory)=> {
					return (

						<div
							className={styles.div}
							key={relatedCategory.name}
						>
							<Link
								to={`/${relatedCategory.url}/`}
								className={styles.text}
								title={relatedCategory.url}
							>
								{relatedCategory.name}
							</Link>
						</div>
					)
				})}
			</>

		)
	}
}
export default SubCategoryList